import { http_client } from "@/plugins/http_client";

const getCandidatos = async (params = {}) =>
  await http_client("/api/v1/ofertas/candidatos", params, "get");
const getCandidato = async (id) => await http_client(`/api/v1/persona/${id}`);
const getDocumentoPersona = async (params = {}) =>
  http_client(
    `/api/v1/documento/persona/documento-tipo/${params.tipo_consulta}/${params.id_persona}/${params.tipo_documento}`,
    {},
    "get"
  );

export default {
  getCandidatos,
  getCandidato,
  getDocumentoPersona,
};
