<template>
  <v-row justify="center">
    <v-dialog
        v-model="value"
        content-class="elevation-0"
        :max-width="maxWidth"
        persistent
    >
      <v-container>
        <v-card
            height="auto"
            style="border-radius: 15px 15px 15px 15px;"
            color="white"
        >
          <v-card-title
              v-if="showClose"
              class="text-h5 primary--text "
          >
            <v-row class="justify-end">
              <v-col cols="2" class="d-flex justify-end">
                <v-icon color="gray" @click="$emit('input', false)">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
            <section class="container">
              <div class="d-flex justify-center mt-5">
                <span class="primary--text font-weight-bold" style="color:#1b1d4b !important;">{{ title }}</span>
              </div>
            </section>
          </v-card-title>

          <v-card-title v-else class="text-h5 primary--text text-center">
            <section class="container">
              <div class="d-flex justify-center mt-5">
                <span class="primary--text">{{ title }}</span>
              </div>
            </section>
          </v-card-title>
          <section class="container px-8">
            <slot name="content"/>
          </section>

          <div class="actions-buttons pl-5 pb-5" :class="classButtons">
            <slot name="actions"/>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    showClose: {
      type: Boolean,
      default: true,
    },
    value: Boolean,
    maxWidth: {
      type: String,
      default: 600,
    },
    classButtons: {
      type: String,
      default: 'd-flex justify-end',
    },
  },
}
</script>

<style scoped>

.actions-buttons button {
  margin-right: auto;
  margin-left: auto;
}
</style>
