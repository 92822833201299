import { http_client } from "@/plugins/http_client";

const getCargos = async (params = {}) =>
  await http_client("/api/v1/cargos", params);
const storeCargo = async (body) =>
  await http_client("/api/v1/cargos", body, "post");
const getCargo = async (id) => await http_client(`/api/v1/cargos/${id}`);
const updateCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}`, body, "put");

// Listados
const getExperiencias = async (params = {}) =>
  await http_client("/api/v1/cargos/listado-experiencias", params);
const getNivelesExperiencia = async (params = {}) =>
  await http_client("/api/v1/nivel-experiencia", params);

// Actividades por cargo
const getActividadesCargo = async (id, params = {}) =>
  await http_client(`/api/v1/cargos/${id}/actividades`, params);
const storeActividadCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}/actividades`, body, "post");
const deleteActividadCargo = async (id) =>
  await http_client(
    `/api/v1/cargos/actividades/${id}`,
    { activo: false },
    "put"
  );

// Formaciones por cargo
const getFormacionesCargo = async (id, params = {}) =>
  await http_client(`/api/v1/cargos/${id}/formacion-educativa`, params);
const storeFormacionCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}/formacion-educativa`, body, "post");
const deleteFormacionCargo = async (id) =>
  await http_client(
    `/api/v1/cargos/formacion-educativa/${id}`,
    { activo: false },
    "put"
  );

// Conocimientos por cargo
const getConocimientosCargo = async (id, params = {}) =>
  await http_client(`/api/v1/cargos/${id}/conocimientos`, params);
const storeConocimientoCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}/conocimientos`, body, "post");
const deleteConocimientoCargo = async (id) =>
  await http_client(
    `/api/v1/cargos/conocimientos/${id}`,
    { activo: false },
    "put"
  );

// Experiencias por cargo
const getExperienciasCargo = async (id, params = {}) =>
  await http_client(`/api/v1/cargos/${id}/experiencia-previa`, params);
const storeExperienciaCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}/experiencia-previa`, body, "post");
const deleteExperienciaCargo = async (id) =>
  await http_client(
    `/api/v1/cargos/experiencia-previa/${id}`,
    { activo: false },
    "put"
  );

// Capacidades por cargo
const getCapacidadesCargo = async (id, params = {}) =>
  await http_client(`/api/v1/cargos/${id}/capacidad`, params);
const storeCapacidadCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}/capacidad`, body, "post");
const deleteCapacidadCargo = async (id) =>
  await http_client(`/api/v1/cargos/capacidad/${id}`, { activo: false }, "put");

// Experiencias por cargo
const getOtrosAspectosCargo = async (id, params = {}) =>
  await http_client(`/api/v1/cargos/${id}/otros-aspectos`, params);
const storeOtroAspectoCargo = async (id, body) =>
  await http_client(`/api/v1/cargos/${id}/otros-aspectos`, body, "post");
const deleteOtroAspectoCargo = async (id) =>
  await http_client(
    `/api/v1/cargos/otros-aspectos/${id}`,
    { activo: false },
    "put"
  );

export default {
  deleteActividadCargo,
  deleteConocimientoCargo,
  deleteExperienciaCargo,
  deleteFormacionCargo,
  getActividadesCargo,
  getCargo,
  getCargos,
  getConocimientosCargo,
  getExperiencias,
  getExperienciasCargo,
  getFormacionesCargo,
  getNivelesExperiencia,
  storeActividadCargo,
  storeCargo,
  storeConocimientoCargo,
  storeExperienciaCargo,
  storeFormacionCargo,
  updateCargo,
  getCapacidadesCargo,
  storeCapacidadCargo,
  deleteCapacidadCargo,
  getOtrosAspectosCargo,
  storeOtroAspectoCargo,
  deleteOtroAspectoCargo,
};
