const routerEmpleo = [
    {
      path: "/seleccion-puesto",
      name: "seleccion-puesto",
      component: () =>
        import(
          /* webpackChunkName: "seleccion-puesto" */ "./cardEmpleo.vue"
        ),
        meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
  ];

  export default routerEmpleo;
