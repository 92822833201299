const routerCargos = [
  {
    path: "/cargos",
    name: "cargos",
    component: () => import(/* webpackChunkName: "cargos" */ "./Puestos"),
    meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/cargo/:id?",
    name: "cargo",
    component: () =>
      import(/* webpackChunkName: "cargosID" */ "./indexCargosTab"),
    meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
];

export default routerCargos;
