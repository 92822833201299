export default [
    {
        path: "/paths",
        name: "paths",
        component: () => import(/* webpackChunkName: "rutasList" */ "./paths.vue" ),
        //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
    {
        path: "/paths/create",
        name: "pathsCreate",
        component: () => import(/* webpackChunkName: "formPath" */ "./pathForm.vue" ),
        //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
    {
        path: "/paths/edit/:id",
        name: "pathsEdit",
        component: () => import(/* webpackChunkName: "formPath" */ "./pathForm.vue" ),
        //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
];
