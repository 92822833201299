import Vue from "vue";
import auth from './auth.services'
import users from './user.services'
import roles from './roles.services'
import profiles from './profiles.services'
import typeRoles from './typeRoles.services'
import paths from './path.services'
import conocimientos from './conocimientos.services'
import capacidad from './capacidad.services'
import area_interes from './area_interes.services'
import nivelEducativo from './nivelEducativo.services'
import formacionEducativa from './formacion.services';
import candidatos from './candidatos.services';
import palabras from './palabras.services';
import ofertas from './ofertas.services';
import cargos from './cargos.services'
import candidato_ofertas from './candidato_ofertas.services'
import preguntas_frecuentes from "./preguntas_frecuentes.services";

Vue.prototype.services = {
    auth,
    users,
    roles,
    profiles,
    typeRoles,
    paths,
    conocimientos,
    capacidad,
    area_interes,
    nivelEducativo,
    formacionEducativa,
    candidatos,
    palabras,
    ofertas,
    cargos,
    candidato_ofertas,
    preguntas_frecuentes
}