import {http_client} from "@/plugins/http_client";

const getPalabras = async (params = {}) => await http_client('/api/v1/palabra-clave', params)
const postPalabras = async (body) => await http_client('/api/v1/palabra-clave', body, 'post')
const putPalabras = async (id) => await http_client(`/api/v1/palabra-clave/${id}`,{}, 'delete')

export default {
    getPalabras,
    postPalabras,
    putPalabras
}