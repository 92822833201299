import {http_client} from "@/plugins/http_client";

const getConocimientos = async (params = {}) => await http_client('/api/v1/conocimiento', params)
const postConocimiento = async (body) => await http_client('/api/v1/conocimiento', body, 'post')
const putConocimiento = async (id, body) => await http_client(`/api/v1/conocimiento/${id}`, body, 'put')


export default {
    getConocimientos,
    postConocimiento,
    putConocimiento
}