import {http_client} from "@/plugins/http_client";

const getAreaInteres = async (params = {}) => await http_client('api/v1/area-de-interes', params)
const postAreaConocimiento = async (body) => await http_client('/api/v1/area-de-interes', body, 'post')
const putAreaInteres = async (id, body) => await http_client(`/api/v1/area-de-interes/${id}`, body, 'put')


export default {
    getAreaInteres,
    postAreaConocimiento,
    putAreaInteres
}