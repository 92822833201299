import { http_client } from "@/plugins/http_client";

const url = "/api/v1/formacion-educativa";
const getFormacionesEducativas = async (params = {}) =>
  await http_client(url, params, "get");
const storeFormacionEducativa = async (body) =>
  await http_client(url, body, "post");
const updateFormacionEducativa = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");

export default {
  getFormacionesEducativas,
  storeFormacionEducativa,
  updateFormacionEducativa,
};
