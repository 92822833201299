import Vue from 'vue'
import Vuex from 'vuex'
import utils from '../modules/utils'
import jwtDecode from 'jwt-decode'
import oferta from '../views/ofertas/store/ofertas'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || null,
        userInfo: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : {},
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
        },
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        clearAuth(state) {
            state.token= null,
            state.userInfo = {}
        }
    },
    actions: {
        setAuth({ commit }, payload) {
            const { token, refreshToken } = payload;
            commit('setUserInfo', jwtDecode(token));
            commit('setToken', token);
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refreshToken);
        },
        logout({ commit }) {
            commit('clearAuth');
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.clear(); // Limpiar todo el localStorage
            window.location.href = '/login'; // Redirige a la página de login

        },
        async checkTokenValidity({ state, dispatch }) {
            if (!state.token) {
                return { valid: false, action: 'redirect' }; 
            }
      
            const token = state.token;
            //console.log('Token found:', token);
      
            const decodedToken = jwtDecode(token);
            //console.log('Decoded token:', decodedToken);
      
            const currentTime = Date.now() / 1000;
            //console.log('Current time:', currentTime);
            //console.log('Token expiration time:', decodedToken.exp);
      
            if (currentTime >= decodedToken.exp) {
                await dispatch('logout');
                return { valid: false, action: 'expired' }; 
            }
      
            return true;
        }
    },
    modules: {
        utils,
        oferta
    }
})
