import pathServices from "../../services/path.services"

export default {
    namespaced: true,
    state: {
        alert: {
            show: false,
            type: "info",
            message: "",
            timeout: 2500,
        },
        loader: false,
        sideBar: true,
        menu: [],
        roles: [],
        rutas: [],
    },
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    mutations: {
        setAlert: (state, payload) => {
            state.alert = payload;
        },
        setLoader: (state, payload) => {
            state.loader = payload;
        },
        setSideBar: (state, payload) => {
            state.sideBar = payload;
        },
        setMenu: (state, menu) => {
            state.menu = menu;
        },
        setRutas: (state, rutas) => {
            state.rutas = rutas;
        },
        setRoles: (state, roles) => {
            state.roles = roles;
        },
    },
    actions: {
        getMenu: async ({commit}) => {
            const response = await pathServices.getAuthorizedPaths()
            const menu = response?.data.menu
            const roles = response?.data.roles
            
            localStorage.setItem("roles",JSON.stringify(roles))
            commit("setMenu", menu);
            commit("setRutas", response?.data);
            commit("setRoles", roles);

        },
        clearMenu: ({commit}) => {
            commit("setMenu", []);
            commit("setRutas", []);
        }
    },
};

const getNavItem = (items) => {
    return items.filter((item) => {
        if (!item.mostrar) return false
        if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens)
        return true
    })
}
