export default [
  {
    path: "/ofertas",
    name: "ofertas",
    component: () =>
      import(/* webpackChunkName: "ofertasList" */ "../views/ofertasList.vue"),
    meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/ofertas/detalles/:id",
    name: "ofertasDetalle",
    component: () =>
      import(
        /* webpackChunkName: "ofertasDetalle" */ "../views/ofertaDetalle.vue"
      ),
      meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/ofertas/candidatos/oferta/:id",
    name: "ofertasCandidatos",
    component: () =>
      import(
        /* webpackChunkName: "ofertasCandidatos" */ "../views/ofertaCandidatos.vue"
      ),
      meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/ofertas/candidato/:id/oferta/:id_oferta",
    name: "ofertasCandidato",
    component: () =>
      import(
        /* webpackChunkName: "ofertasCandidato" */ "../views/ofertaCandidatoDetalle.vue"
      ),
      meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/ofertas/lista-candidatos/:id",
    name: "CandidatosLista",
    component: () =>
      import(
        /* webpackChunkName: "CandidatosLista" */ "../components/candidatosList.vue"
      ),
      meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/ofertas/candidato/:id/invitacion/:invitacion/oferta/:id_oferta",
    name: "CandidatoOferta",
    component: () =>
      import(
        /* webpackChunkName: "CandidatosLista" */ "../components/candidatoDetail.vue"
      ),
      meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  // {
  //     path: "ofertas/edit/:id",
  //     name: "ofertasEdit",
  //     component: () =>
  //         import(
  //             /* webpackChunkName: "OdEdit" */ "./usersEdit.vue"
  //         ),
  // },
];
