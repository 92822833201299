import { http_client } from "@/plugins/http_client";

const url = "/api/v1/capacidad";
const getCapacidades = async (params = {}) =>
  await http_client(url, params, "get");
const storeCapacidad = async (body) => await http_client(url, body, "post");
const updateCapacidad = async (id, body) =>
  await http_client(`${url}/${id}`, body, "put");

export default {
  getCapacidades,
  storeCapacidad,
  updateCapacidad,
};
