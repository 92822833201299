<template>

  <v-navigation-drawer
    :value="sideBar"
    fixed
    app
    @input="setSideBar($event)"
    style="border-radius: 0px 15px 15px 0px; box-shadow: 2px 2px 15px #cecece"
    color="#ffffff"
    width="356"
  >

    <template v-slot:prepend>
      <v-list-item two-line>
        <v-img class="mt-15 mb-15" src="@/assets/img/Capa_1.svg"></v-img>
      </v-list-item>
    </template>

    <v-list>
      <span v-for="(item, i) in menu" :key="i">
        <!-- Tiene hijos -->
        <v-list-group
          :value="false"
          active-class="selectedBgPurple--text"
          v-if="item.dependencias && item.dependencias.length"
        >
          <template #prependIcon>
            <v-icon color="#313945">{{ item.icono }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title
              style="
                color: #313945;
                font-size: 0.8125rem;
                font-weight: 500 !important;
                line-height: 1rem;
              "
              >{{ item.titulo }}</v-list-item-title
            >
          </template>

          <span v-for="(child, j) in item.dependencias" :key="j">
            <v-list-group
              :value="false"
              no-action
              sub-group
              active-class="selectedBgPurple--text"
              v-if="child.dependencias && child.dependencias.length"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    style="
                      color: #313945;
                      font-size: 0.8125rem;
                      font-weight: 500 !important;
                    "
                    >{{ child.titulo }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(sub_child, k) in child.dependencias"
                :key="k"
                @click="redirect(`${item.modulo.base_url + sub_child.ruta}`)"
              >
                <v-list-item-title
                  style="
                    color: #313945;
                    font-size: 0.8125rem;
                    font-weight: 500 !important;
                  "
                  >{{ sub_child.titulo }}</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon color="#313945">{{ sub_child.icono }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              active-class=" selectedBgPurple--text"
              @click="redirect(`${item.modulo.base_url + child.ruta}`)"
            >
              <v-list-item-icon>
                <v-icon color="#313945">{{ child.icono }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title
                style="
                  color: #313945;
                  font-size: 0.8125rem;
                  font-weight: 500 !important;
                "
                >{{ child.titulo }}</v-list-item-title
              >
            </v-list-item>
          </span>
        </v-list-group>
        <!-- No tiene hijos -->
        <v-list-item
          v-else
          @click="redirect(`${item.modulo.base_url + item.ruta}`)"
          active-class=" selectedBgPurple--text"
        >
          <v-list-item-icon>
            <v-icon color="#313945">{{ item.icono }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title
            style="color: #313945; font-size: .8125rem; font-weight: 500 !important"
            >{{ item.titulo }}</v-list-item-title
          >
        </v-list-item>
      </span>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    redirect(url) {
      window.location = url;
    },
  },
  computed: {
    ...mapState("utils", ["sideBar"]),
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return !this.sideBar;
      } else if (this.$vuetify.breakpoint.xs) {
        return !this.sideBar;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return !this.sideBar;
      }
    },
  },
  
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  
  
};
</script>

<style scoped>
:deep(.v-list-group__items) {
  flex: 1 1 auto;
  /* background-color: #f5f9ff; */
  background-color: #ffff;
  border-radius: 10px;
}
:deep(.mdi-chevron-down::before) {
  content: "\F0140";
  color: #313945;
}
</style>
