import {http_client} from "@/plugins/http_client";

const getPreguntas = async (body={}) => await http_client(`/api/v1/preguntas-frecuentes/`,body,'get')
const deletePreguntas = async (id,body={}) => await http_client(`/api/v1/preguntas-frecuentes/${id}`,body,'delete')
const postPreguntasFrecuentes = async (body) => await http_client('api/v1/preguntas-frecuentes', body, 'post')
const getPreguntasFrecuentesId = async (id,body={}) => await http_client(`/api/v1/preguntas-frecuentes/${id}`,body,'get')
const putPreguntasFrecuentesId = async (id,body={}) => await http_client(`/api/v1/preguntas-frecuentes/${id}`,body,'put')

export default {
    postPreguntasFrecuentes,
    getPreguntasFrecuentesId,
    putPreguntasFrecuentesId,
    getPreguntas,
    deletePreguntas,
}