export default {
    namespaced: true,
    state: {
      estadoOferta: null,
      tipoConcurso: null,
      idBitacoraOferta: null,
      //Candidatos:
      id_estado_oferta: null
    },
    mutations: {
      setEstadoOferta(state, payload) {
        state.estadoOferta = payload;
      },
      setTipoConcurso(state, payload) {
        state.tipoConcurso = payload;
      },
      setIdBitacoraOferta(state, payload) {
        state.idBitacoraOferta = payload;
      },
      //candidatos:
      setIdEstadoOferta(state, payload) {
        state.id_estado_oferta = payload;
      }
    },
  };